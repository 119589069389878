<template>
  <div class="section">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table
      v-else
      max-height="80vh"
      :columns="columns"
      :rows="transfers"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Total -->
        <span v-if="props.column.field === 'warehouse_origin'">
          <span>{{ (props.row.warehouse_origin && props.row.warehouse_origin.name || props.row.supplier_origin && props.row.supplier_origin.name) || '-' }}</span>
        </span>

        <span v-else-if="props.column.field === 'delivery_number'">
          <span>{{ props.row.delivery_number || '-' }}</span>
        </span>

        <!-- Column: Delivery Numbers -->
        <span v-else-if="props.column.field === 'warehouse_destination'">
          <span>
            {{ props.row.warehouse_destination.name || '-' }}
          </span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <b-dropdown-item @click="$router.push({ name: 'stock.transfer_detail', params: { id: props.row.uuid } })">
                <span>
                  Lihat Detail
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && !isLoading"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getDataProduct"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormSelect, VBToggle, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
// import ModalIncomeProduct from '@/components/Stock/Modal/ModalIncomeProduct.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormSelect,
    BSpinner,
    // ModalIncomeProduct,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    resultProduct: {
      type: Object,
    },
    getDataProduct: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      columns: [
        {
          label: 'Tanggal',
          field: 'date',
        },
        {
          label: 'No. Surat Jalan',
          field: 'delivery_number',
        },
        {
          label: 'Lokasi SKU Asal',
          field: 'warehouse_origin',
        },
        {
          label: 'No. Surat Jalan SKU Asal',
          field: 'delivery_number',
        },
        {
          label: 'Lokasi SKU Tujuan',
          field: 'warehouse_destination',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      transfers: [],
      filter: {
        per_page: 10,
      },
    }
  },
  watch: {
    resultProduct: {
      handler(value) {
        this.rows = value
        this.transfers = value.data
      },
      deep: true,
    },
    filter: {
      handler(value) {
        this.$emit('filterIncomeProduct', value)
      },
      deep: true,
    },
  },
  methods: {
    returOrder(id) {
      this.isLoading = true
      this.$store.dispatch('stockWarehouse/getData', {
        uuid: `${id}`,
        params: '',
      }).then(result => {
        this.isLoading = false
        this.$store.commit('order/setDataRetur', result.data.data)
        this.$bvModal.show('modal-retur')
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
}
</style>
